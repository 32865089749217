import Image, {ImageProps} from 'next/legacy/image';

interface DynamicImageProps extends ImageProps {
  alt: string;
}

/**
 * Wrapper around the next/image Image component with app defaults.
 * Use this one rather than the next/image Image component directly.
 */
export const DynamicImage: React.FC<DynamicImageProps> = ({
  alt,
  objectFit = 'cover',
  quality = 100,
  ...props
}) => {
  return <Image objectFit={objectFit} quality={quality} {...props} alt={alt} />;
};
